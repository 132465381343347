import logo from './mainLogo.svg';

import visionLogo from './visionLogo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
         <b> Coming soon</b>
         <br /><br />
          <br />
          See more products:
        </p>
      

        <a href='https://vision.atomicagroup.com/'>
        <img src={visionLogo} className="vision-logo" alt="logo" />  
    <br/><br />
       <b> Realistic Virtual Makeup Try-On</b> <br/><br />
    for your webstore to improve client  <br /> satisfaction and minimize returns
        </a>
      </header>
    </div>
  );
}

export default App;
